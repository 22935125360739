$(document).on("change", ".driver-extra-task-checkbox", function() {
  $.ajax({
    type: "PATCH",
    url: `/drivers_portal/driver_extra_tasks/${$(this).val()}`,
    data: $("#completed").is(":checked"),
    success: function(data) {}
  });
});

$(document).on("change", ".supply-order-completed-checkbox", function() {
  $.ajax({
    type: "PATCH",
    url: `/drivers_portal/supplies_orders/${$(this).val()}`,
    success: function() {}
  });
});

$(document).on("click", "#drivers-portal-scan-button", function() {
  $("#drivers-portal-scan-form").show();
  $("#order_barcode").focus();
});

$(document).on("change", "#print-receipt-signed-driver-orders-group", function() {
  if (this.value !== "") {
    let data = $(this).data();
    let path = ''
    if(data.subLocation){
      path = `/drivers_portal/service_orders.pdf?date=${data["date"]}&route=${data["route"]["id"]}&driver_orders_group_id=${this.value}&sub_location=${true}`;
    } else {
      path = `/drivers_portal/service_orders.pdf?date=${data["date"]}&location=${data["location"]}&location_id=${data["locationId"]}&route=${data["route"]["id"]}&driver_orders_group_id=${this.value}`;
    }

    window.open(path, "_blank")
  }
})

$(document).on("turbolinks:load", function() {
  $('.driver_extras').click(function() {
    var checkbox = $(this);
    var supply_productID = checkbox.data('supply_product-id');
    var driverExtrasValue = checkbox.is(':checked');

    $.ajax({
      url: '/update_driver_extras',
      method: 'PATCH',
      data: { supply_product_id: supply_productID, driver_extra: driverExtrasValue },
      success: function(response) {
      },
      error: function(xhr, status, error) {
      }
    });
  });
});

document.addEventListener("turbolinks:load", function() {
  const backLink = document.querySelector("#backward-link");
  function isCompleteVisitButtonVisible() {
    const completeVisitButton = document.querySelector("#visible_complete_visit");
    if (!completeVisitButton) return false;

    const style = window.getComputedStyle(completeVisitButton);
    return style.display !== 'none' && style.visibility !== 'hidden' && completeVisitButton.offsetHeight > 0;
  }

  if (backLink) {
    backLink.addEventListener('click', function(event) {
      if (isCompleteVisitButtonVisible()) {
        const message = "Please mark the visit as complete to proceed further.";
        if (!window.alert(message)) {
          event.preventDefault();
        }
      } else {
        const uncheckedCheckboxes = document.querySelectorAll('.driver_extras:not(:checked)').length;
        if (uncheckedCheckboxes > 0) {
          if (!window.confirm("Are you sure you want to leave? There are unchecked items remaining.")) {
            event.preventDefault();
          }
        }
      }
    });
  }
});
$(document).on("turbolinks:load", function() {
  $('.truck_loading').click(function() {
    var checkbox = $(this);
    var supply_productID = checkbox.data('supply_product-id');
    var truckLoadingValue = checkbox.is(':checked');

    $.ajax({
      url: '/update_truck_loading',
      method: 'PATCH',
      data: { supply_product_id: supply_productID, truck_loading: truckLoadingValue },
      success: function(response) {
      },
      error: function(xhr, status, error) {
      }
    });
  });
});

document.addEventListener("turbolinks:load", function() {
  const backLink = document.querySelector("#back-link");
  if (backLink) {
    backLink.addEventListener("click", function(event) {
      const uncheckedCheckboxes = document.querySelectorAll('.truck_loading:not(:checked)').length;
      if (uncheckedCheckboxes > 0 && !window.confirm(" Are you sure you want to leave? There are unchecked items remaining.")) {
        event.preventDefault();
      }
    });
  }
});