// Use Turbolinks and jQuery together
document.addEventListener("turbolinks:load", function () {
  // Tooltip initialization
  $('[data-bs-toggle="tooltip"]').tooltip();

  // Edit button event listener
  $("#editIcon").on("click", function (e) {
    e.preventDefault();
    toggleEditDiv();
  });

  // Edit div event listeners
  var editDiv = $("#editDiv");
  var cancelBtn = $("#cancelBtn");

  function toggleEditDiv() {
    editDiv.toggle();
  }
  function openSecondModal() {
    $("#secondModal").modal("show");
  }  

  cancelBtn.on("click", function () {
    editDiv.hide();
  });

  // Open bin location modal
  $("#open-bin_location-modal").on("click", function () {
    $("#bin_location-modal").modal("show");
  });

  // Open arrived modal
  $("#open-arrived-modal").on("click", function () {
    $("#arrived-modal").modal("show");
  });

  // Second modal
  $("#openSecondModalButton").on("click", openSecondModal);

  var openSecondModalButtons = document.querySelectorAll(
    '[id^="openSecondModalButton_"]'
  );
  openSecondModalButtons.forEach(function (button) {
    button.addEventListener("click", function () {
      var orderId = this.id.split("_")[1];
      $("#secondModal_" + orderId).modal("show");
    });
  });

  var arrivedModal = document.getElementById("arrived-modal");
  if (arrivedModal) {
    arrivedModal.addEventListener("show.bs.modal", function () {
      var originalBinLocation = document.getElementById("bin_location").value;
      document.querySelector('input[name="bin[bin_location]"]').value =
        originalBinLocation;
    });
  }

  var openArrivedModalButton = document.getElementById("open-arrived-modal");
  if (openArrivedModalButton) {
    openArrivedModalButton.addEventListener("click", function () {
      var confirmPetArrived = this.dataset.confirmPetArrived;
      document.getElementById("confirm-pet-arrived-input").value =
        confirmPetArrived;
      $("#arrived-modal").modal("show");
    });
  }

  if ($("#open-arrived-modal").is(":visible")) {
    $(".selectAll-wrap").css("display", "none");
    $(".binCheckboxes").css("display", "none");
  } else {
    $(".selectAll-wrap").css("display", "flex");
  }
  function changeButtonColor(prevColor, newColor) {
    $("#emptyBinButton")
      .removeClass(`text-${prevColor}`)
      .addClass(`text-${newColor}`);
    $("#emptyBinButton")
      .removeClass(`border-${prevColor}`)
      .addClass(`border-${newColor}`);
    $(".emptyBin_trashIcon")
      .removeClass(`text-${prevColor}`)
      .addClass(`text-${newColor}`);
  }

  function isAnyCheckboxesSelected() {
    if ($(".binCheckboxes:checked").length) {
      changeButtonColor("secondary", "danger");
      $("#emptyBinButton").prop("disabled", false);
    } else {
      changeButtonColor("danger", "secondary");
      $("#emptyBinButton").prop("disabled", true);
    }
  }

  $("#selectAllBinCheckboxes").on("change", function () {
    $("#emptyBinButton").prop("disabled", false);
    $(this).prop("checked", $(this).is(":checked"));
    $(".binCheckboxes").prop("checked", $(this).is(":checked"));
    let checkedCount = $(".binCheckboxes:checked").length;
    $("#selectAllLabel").text(`Select All (${checkedCount})`);
    isAnyCheckboxesSelected();
  });

  $(".binCheckboxes").on("change", function () {
    let isChecked = $(this).is(":checked");
    $(this).prop("checked", isChecked);
    if (!isChecked) {
      $("#selectAllBinCheckboxes").prop("checked", false);
    }
    let allChecked =
      $(".binCheckboxes:checked").length === $(".binCheckboxes").length;
    if (allChecked) {
      $("#selectAllBinCheckboxes").prop("checked", true);
    }
    let checkedCount = $(".binCheckboxes:checked").length;
    $("#selectAllLabel").text(`Select All (${checkedCount})`);
    isAnyCheckboxesSelected();
  });

  $("#emptyBinButton").on("click", function () {
    $("#orderIdsBox").modal("show");
  });

  $("#orderIdsLocation").on("change", function () {
    $(".orderIdsFieldError").text("");
  });

  $("#orderIdsConfirmBtn").on("click", function () {
    let bin_location_orderIds = $("#orderIdsLocation").val();
    if (bin_location_orderIds) {
      let selectedOrderIds = [];
      $(".binCheckboxes").each(function () {
        if ($(this).is(":checked")) {
          selectedOrderIds.push($(this).data("order-id"));
        }
      });
      let currentPath = window.location.pathname;
      let binId = currentPath.split("/").pop();
      binId = parseInt(binId, 10);
      let apiRequestData = {
        bin_id: binId,
        bin_location_orderIds,
        order_ids: selectedOrderIds,
      };
      $.ajax({
        url: `/bins/${binId}/remove_multiple_orders_from_bin`,
        method: "PATCH",
        data: apiRequestData,
        success: function (response) {},
        error: function (xhr, status, error) {
          console.error("Error removing orders from bin");
        },
      });
    } else {
      $(".orderIdsFieldError").text("This is a required field");
    }
  });
});