$(document).on("turbolinks:load", function() {
  checkScreenSize();

  $(".countdown, .paused-countdown").each(function() {
    runTimerOnIndexPage();
  })

  $(document).on("content_changed", function() {
    runTimerOnIndexPage();
  })

  $('.chamber-cards').each(function() {
    var countdownSpan = $(this).find('.countdown');
    var timeStatus = $(countdownSpan[0]).attr('data-timer-status');
    if(countdownSpan.length > 1){
      timeStatus = $(countdownSpan[1]).attr('data-timer-status');
    }
    if($(this).children('.countdown').length === 0){
      if (timeStatus === 'finished') {
        $(this).css('background', '#FDE4E2');
        $(this).css('border', '1px solid red');
        $(this).addClass('shake')
      }else{
        $(this).css('background', 'white');
      }
    }
    if(countdownSpan.has('.paused-countdown').length){
      $(this).css('border', '1px solid blue');
      $(this).css('background', 'rgb(10 99 185 / 10%)');
    }
    if (timeStatus === 'in_progress') {
      let timeParts = countdownSpan.text().split(':');
      let fetchedMinutes = timeParts[1];
      if(Number(fetchedMinutes) < 5 ){
        $(this).css('background', '#fff9cd');
        $(this).css('border', '1px solid #EED83D');
      }else{
        $(this).css('background', '#d8e3d8');    
        $(this).css('border', '1px solid green');
      }
    }

  });

})

window.checkScreenSize = () => {
  const width = $(window).width();

  if (width >= 800) {
    $(".js-alert-mobile").addClass("alert-general");
    $(".js-cremation-mobile").remove();
    $(".js-crematory-modal-body").removeClass("mx-2").addClass("mx-4");
  }
  else {
    $(".js-alert-mobile").removeClass("alert-general");
    $(".js-cremation-desk").remove();
    $(".js-crematory-modal-body").removeClass("mx-4").addClass("mx-2");
  }
}

function updateChamberTimer(id, status, remainingTime, location) {
  $.ajax({
    type: "PATCH",
    url: `/chamber_zone_timers/${id}`,
    data: {
      chamber_zone_timer: {
        status: status,
        remaining_time: remainingTime
      },
      modal_window: $("#ajax-modal").length > 1,
      location: location
    },
    success: {}
  });
}

function chamberTimerLogic(guiTimer, guiPause, guiResume, redirectLocation, runGuiTimer, elapsedTimerElement = null) {
  const fixedDateFormat = attribute => {
    if (GuiTimer.data(attribute) !== undefined) {
      let stringDate = GuiTimer.data(attribute);
      let formattedStringDate;

      if (stringDate.includes('UTC')) {
        formattedStringDate = stringDate.replace(/-/g, "/");
      } else {
        formattedStringDate = stringDate.replace(/[-](?=.*[-])/g, "/");
      }

      let dateUTC = new Date(formattedStringDate).toUTCString();

      return Date.parse(dateUTC);
    }
  }

  let TimeOut = 10000;
  let TimeGap = 1000;
  let GuiTimer = $(guiTimer);
  let GuiPause = $(guiPause);
  let GuiResume = $(guiResume);
  let CurrentTime = fixedDateFormat("timerCurrent");
  let parentDiv = guiTimer.closest(".chamber-cards");

  let ChamberZoneTimerId = GuiTimer.data("chamberZoneTimerId");
  let location = redirectLocation;
  let timerStatus = GuiTimer.data("timerStatus");
  let Running = ["in_progress", "finished"].includes(timerStatus);
  let elapsedTimer;

  if (elapsedTimerElement === null) {
    elapsedTimer = $(`div.countdown-elapsed-time[data-chamber-zone-timer-id="${ChamberZoneTimerId}"]`);
  } else {
    elapsedTimer = elapsedTimerElement;
  }

  let timerTime = elapsedTimer.data("timerTime");

  let UpdateTimer = function() {
    let EndTime = fixedDateFormat("timerEnd");
    let elapsedStartTime = fixedDateFormat("timerElapsedTimeStart");

    if (CurrentTime + TimeGap < EndTime || timerStatus === "finished") {
      parentDiv.css('background', '#FDE4E2')
      parentDiv.css('border', '1px solid red');
      parentDiv.addClass('shake')
      setTimeout(UpdateTimer, TimeGap);
    }

    if (Running && timerStatus !== "finished") {
      CurrentTime += TimeGap;

      if (CurrentTime >= EndTime) {
        // GuiTimer.css("color", "green");

        if ($("#resume").length === 0) {
          const sound = new Audio("alarm_clock.mp3");
          sound.loop = true;
          sound.play();

          updateChamberTimer(ChamberZoneTimerId, "finished", "00:00:00", location);

          $(`a[data-chamber-zone-timer-id="${ChamberZoneTimerId}"]`).click();
        }
      }
    }

    let Time = new Date();
    Time.setTime(EndTime - CurrentTime);

    let Hours = (EndTime - CurrentTime) / 36e5 > 1 ? Math.ceil(Math.abs(EndTime - CurrentTime) / 36e5) : 0;

    if (timerTime !== undefined && Hours > 1) {
      Hours -= 1;
    }

    let Minutes = Time.getMinutes();
    let Seconds = Time.getSeconds();

    if (runGuiTimer) {
      if (timerStatus === "in_progress") {
        parentDiv.css('background', '#d8e3d8');
        parentDiv.css('border', '1px solid green');
        parentDiv.removeClass('shake')

        let hoursString = `${Hours < 10 ? "0" : ""}${Hours} : `;
        let minutesString = `${Minutes < 10 ? "0" : ""}${Minutes} : `;
        let secondsString = `${Seconds < 10 ? "0" : ""}${Seconds}`;
        
        let formattedTime = ((microseconds) => `${String(Math.floor(microseconds / 3600000)).padStart(2, '0')}:${String(Math.floor((microseconds % 3600000) / 60000)).padStart(2, '0')}:${String(Math.floor((microseconds % 60000) / 1000)).padStart(2, '0')}`)(EndTime - CurrentTime);
        let timeParts = formattedTime.split(':');
        let fetchedMinutes = timeParts[1];
        if(timeParts[0] < 0 || timeParts[1] < 0 || timeParts[2] < 0){
          GuiTimer.html("00:00:00");
        }else{
          GuiTimer.html(`${formattedTime}`);
        }
        if(Number(fetchedMinutes) < 5 ){
          parentDiv.css('background', '#fff9cd');
          parentDiv.css('border', '1px solid #EED83D');
        }
      }

      let timeDifference = (Date.now() - elapsedStartTime) / 1000;
      let hours = Math.floor(timeDifference / 3600);
      let minutes = Math.floor((timeDifference - (hours * 3600)) / 60);
      let seconds = Math.floor(timeDifference - (hours * 3600) - (minutes * 60));

      let hoursString = `${hours < 10 ? "0" : ""}${hours} : `;
      let minutesString = `${minutes < 10 ? "0" : ""}${minutes} : `;
      let secondsString = `${seconds < 10 ? "0" : ""}${seconds} total`;

      elapsedTimer.html(`${hoursString} ${minutesString} ${secondsString}`);

      if (redirectLocation === "from_modal") {
        $("#countdown").on("DOMSubtreeModified", function() {
          $(`div.countdown[data-chamber-zone-timer-id="${ChamberZoneTimerId}"]`).html(GuiTimer.html());
          $(`div.countdown-elapsed-time[data-chamber-zone-timer-id="${ChamberZoneTimerId}"]`).html(elapsedTimer.html());

          let fullTime = $("#countdown-elapsed-time").data("chamberTimerFullTime");
          let timeGap = Math.floor(Math.abs((fullTime - Time) / 1000));
          let progressPercent = Math.round((timeGap / fullTime) * 100);

          scrollInfo(progressPercent);
        });
      }
    }
  };

  let Pause = function(object = null, closestBoth = false) {
    Running = false;

    GuiPause.hide();
    GuiResume.show();

    if (location === "from_modal") {
      const countdown = $("#countdown");
      let id = countdown.data("chamberZoneTimerId");

      updateChamberTimer(id, "paused", countdown.html(), "from_modal");
    } else if (location === "from_page") {
      let countdown = object.closest(".chamber-actions-block").find(".countdown");
      let id = countdown.data("chamberZoneTimerId");

      updateChamberTimer(id, "paused", countdown.html(), "from_page");
    }
  };

  let Resume = function(object = null) {
    Running = true;

    GuiPause.show();
    GuiResume.hide();

    if (location === "from_modal") {
      let id = $("#countdown").data("chamberZoneTimerId");

      updateChamberTimer(id, "in_progress", "", "from_modal");
    } else {
      let id = object.closest(".chamber-actions-block").find(".paused-countdown").data("chamberZoneTimerId");

      updateChamberTimer(id, "in_progress", "", "from_page");
    }
  };

  let Start = function(Timeout) {
    TimeOut = Timeout;

    UpdateTimer();
  };

  return {
    Pause: Pause,
    Resume: Resume,
    Start: Start
  };
}

function scrollInfo(percent) {
    let scrollInfo = $(".time-progress-circle");
    let minValue = 0;
    let maxValue = 300;
    let progressPercent = 100 - percent
    let stroke = maxValue - maxValue / 100 * progressPercent;

    stroke = stroke < minValue ? minValue : stroke;
    stroke = stroke > maxValue ? maxValue : stroke;

    $(scrollInfo).attr("stroke-dashoffset", "" + stroke);
}

$(document).on("click", "#chamber-zone-timer-cancel-timer", function() {
  $(this).addClass("d-none");
  $(".time-circle").addClass("d-none");
  $("#resume").addClass("d-none");
  $(".chamber-zone-timer-new-time").closest("div").removeClass("d-none");
  $("#chamber-zone-timer-start-timer").removeClass("d-none");
})

$(document).on("click", "#chamber-zone-timer-start-timer", function() {
  let time = $(".chamber-zone-timer-new-time").map(function() {
    return $(this).val();
  })[0];

  let id = $("#chamber-zone-timer-start-timer").data("timerId");

  $.ajax({
    type: "PATCH",
    url: `/chamber_zone_timers/${id}`,
    data: {
      chamber_zone_timer: {
        time: time
      }
    },
    success: {}
  });
})


function runTimerOnIndexPage() {
  $(".countdown, .paused-countdown").each(function() {
    let parentDiv = $(this).closest(".chamber-actions-block");
    let CountDown = chamberTimerLogic($(this), parentDiv.find(".pause"), "", "from_page",
                           $(this).attr("class") === "countdown");

    $(document).on("click", ".resume", function() {
      CountDown.Resume($(this));
    })

    $(document).on("click", ".pause", function() {
      CountDown.Pause($(this));
    })

    // ms
    CountDown.Start();
  })
}

$(document).on("click", "#add_extra_time_button", function(e) {
  e.preventDefault();
  let chamber_zone_timer = $(this).data('object-id');
  let add_extra_time = $(`#add-extra-time-${chamber_zone_timer}`).val();
  if($(`.add_more_time_${chamber_zone_timer}`).is(":hidden")){
    $(`.add_more_time_${chamber_zone_timer}`).show()
    return
  }
  if(add_extra_time === ''){
    $(`.add_more_time`).text("Add more time can't be empty.")
    return
  }
  $.ajax({
    type: "get",
    url: "chamber_zone_timers/add_extra_time",
    data: {
      chamber_zone_timer: chamber_zone_timer,
      add_extra_time: add_extra_time
    },
    success: function(response) {
        window.location.reload()
    }
  });
})
$(document).on("click", ".flame-buttons", function() {
  $(".flame-buttons").removeClass("active-button");
  $(this).addClass("active-button");
  var value = $(this).data('value');
  $('#crematory_crematory_type').val(value);
});

$(document).ready(function() {
  $(document).on('click', '.chamberModal .btn-close', function(e) {
    e.preventDefault();
    location.reload();
  });
  $(document).on('click', '.chamberModal .modal-footer a', function(e) {
    e.preventDefault();
    location.reload();
  });
});
