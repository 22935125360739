function validateDefaultProductsFields(fieldClass, errorClass) {
  let anyError = false;
  $(`.${fieldClass}`).each(function() {
    var $select = $(this).find('select');
    var selectedValue = $select.val();
    var optionsCount = $select.find('option').length;
    
    if (optionsCount > 1) {
      if(selectedValue === ''){
        anyError = true;
        if($(this).find(`span.${errorClass}`)?.length === 0){
          var errorMessage = $(`<span class=${errorClass} style="color: red;">This field is required.</span>`);
          $(this).append(errorMessage);
        }
      }else{
        $(this).find(`span.${errorClass}`).text('');
      }
    }
  });
  return anyError;
}


$(document).on('select2:select', '.js-default-product', function (e) {
  let productId = e.params.data.id;
  let standardReturnId = $(this).data('standardReturnId');
  let standardReturnItemId = $(this).data('standardReturnItemId');
  
  // TODO: refactor this let
  let nestedItemIndex = $(this).children()[1]['name']
    .replace('standard_return[standard_return_items_attributes][', '')
    .replace('][default_product_id]', '');

  $(this).siblings().closest('.js-default-product-variant').addClass(`js-default-product-variant-${nestedItemIndex}`);

  $.get({
    dataType: 'script',
    url: `/standard_returns/${standardReturnId}/edit`,
    data: {
      product_id: productId,
      standard_return_item_id: standardReturnItemId,
      nested_item_index: nestedItemIndex
    }
  });
});

$(document).on('click', '#standard-return-submit', function(event) {
  const convertedParams = serializeFormToNestedObject($(this).parents("form"));
  if(validateDefaultProductsFields('standard_return_standard_return_items_default_product', 'defaultSelectionError')){
    event.preventDefault();
  }else if(validateDefaultProductsFields('js-default-product-variant', 'defaultProductVariantError')){
    event.preventDefault();
  }
  if (!checkUniquePairs(convertedParams)) {
    event.preventDefault();
  }
  const isCheckboxChecked = $('#chargeForProductCheckbox').is(':checked');
  if (isCheckboxChecked === true){
  if(!checkItemPrice()){
    event.preventDefault();
  }}
});

function serializeFormToNestedObject(form) {
  return Array.from(form.serializeArray())
    .reduce((obj, { name, value }) => {
      const keys = name.match(/[^\[\]]+/g) || [];
      buildNestedObject(obj, keys, value);
      return obj;
    }, {});
}

function buildNestedObject(obj, keys = [], value) {
  const key = keys.shift();
  if (!keys.length) {
    obj[key] = Array.isArray(obj[key]) ? obj[key].concat(value) : [obj[key], value];
  } else {
    obj[key] = obj[key] || {};
    buildNestedObject(obj[key], keys, value);
  }
}

function checkUniquePairs(params) {
  const seenPairs = new Set();
  const items = params.standard_return.standard_return_items_attributes;
  const entries = Object.entries(items);
  let isValid = true;
  let default_price = params.standard_return.default_price[1];
  let price_per_additional_unit = params.standard_return.price_per_additional_unit[1];

  if(params.standard_return.name[1].length === 0){
    isValid = false;
    $('.standard-return-name').text("Standard Return Name can't be blank");
  }else{
    $('.standard-return-name').text("");
  }

  if (default_price === '') {
    isValid = false;
    $('.default_price').text("Default Price can't be blank.");
  }else if(isNaN(default_price) || (parseFloat(default_price) < 0 || parseFloat(default_price) > 99999999)){
    isValid = false;
    $('.default_price').text("The default price must be an integer and can have a maximum of 8 digits.");
  }else{
    $('.default_price').text("");
  }

  if (price_per_additional_unit === '') {
    isValid = false;
    $('.price_per_additional_unit').text("Price Per Additional Unit can't be blank.");
  }else if(isNaN(price_per_additional_unit) || (parseFloat(price_per_additional_unit) < 0 || parseFloat(price_per_additional_unit) > 99999999)){
    isValid = false;
    $('.price_per_additional_unit').text("The price per additional unit must be an integer and can have a maximum of 8 digits.");
  }else{
    $('.price_per_additional_unit').text("");
  }

  for (let index = 0; index < entries.length; index++) {
    const [key, item] = entries[index];
    const productIds = item.product_ids.filter(id => id);
    const serviceTypes = item.service_types.filter(type => type);
    const description = item.description[1];

    if (description.length === 0) {
      $('.description').eq(index).text("Description can't be blank.");
      isValid = false;
    }else{
      $('.description').eq(index).text("");
    }

    if (productIds.length === 0) {
      $('.productId').eq(index).text("Products can't be blank.");
      isValid = false;
    }else{
      $('.productId').eq(index).text("");
    }

    if (serviceTypes.length === 0) {
      $('.service_type').eq(index).text("Service type can't be blank.");
      isValid = false;
    }else{
      $('.service_type').eq(index).text("");
    }

    for (const productId of productIds) {
      for (const serviceType of serviceTypes) {
        const pair = `${productId}_${serviceType}`;

        if (seenPairs.has(pair)) {
          $('.duplicate').eq(index).text('Product options have already been taken.');
          isValid = false;
        }

        seenPairs.add(pair);
      }
    }
  }

  if (!isValid) {
    return false;
  }

  return true;
}

function checkItemPrice() {
  var elements = $('.item_price_validation');
  var validationMessages = $('.item-price-validation');
  var isValid = true;
  elements.each(function (i) {
    var element = $(this);
    var value = element.val();

    if (value === '') {
      isValid = false;
      validationMessages.eq(i).text("Item price can't be empty.");
    }else if(isNaN(value) || (parseFloat(value) < 0 || parseFloat(value) > 99999999)){
      isValid = false;
      validationMessages.eq(i).text("The item price must be an integer and can have a maximum of 8 digits.");
    }else {
      validationMessages.eq(i).text("");
    }
  });
  return isValid;
}

$(document).on('click', '.remove-link', function() {
  var $closestElement = $(this).closest('.standard_return_item');
  var firstRemainingClass = $closestElement.attr('class').split(' ').find(className => className !== 'standard_return_item');
  var numericValue = parseFloat(firstRemainingClass);
  
  $('input[type="hidden"][value="' + numericValue + '"]').remove();
  $closestElement.remove();
});