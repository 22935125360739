document.addEventListener("turbolinks:load", function() {
  $(document).on("content_changed", function () {
    const $priceForm = $("#price-form");

    if ($priceForm.length) {
      $priceForm.on("cocoon:before-insert", function(e, insertedItem) {
        const $nestedFields = $("div.nested-fields:not([style='display: none;'])");
        const weightMax = $nestedFields.find(".price_price_items_weight_max input").last().val();

        $(insertedItem).find(".price_price_items_weight_min input").val(parseInt(weightMax) + 1);
      });

      $priceForm.on("cocoon:after-insert", () => {
        $(".price_price_items_weight_min input").first().val('0');
      })
    }
  });
});
