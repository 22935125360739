$(document).on("click", "#drivers-portal-calendar-icon", function () {
  $(this).closest("div.d-flex").find("input.js-drivers-portal-calendar:visible").click();
})

$(document).on("click", "#engraving-order-date-calendar-icon", function () {
  $(this).closest("td").find("input.js-engraving-order-date-calendar:visible").click();
})

$(document).on("click", "#engraving-received-date-calendar-icon", function () {
  $(this).closest("td").find("input.js-engraving-received-date-calendar:visible").click();
})

function initRouteSelect() {
  $(".js-drivers-portal-routes").select2({
    dropdownAutoWidth: true,
    minimumResultsForSearch: Infinity,
  });
}

$(document).on("content_changed load", function() {
  initRouteSelect();
  changeUrlOnRouteChange();
  changeUrlOnDateChange();
});

$(document).on("turbolinks:load", function() {
  $(".select2.select2-container.select2-container--default").last().remove();

  initRouteSelect();
  changeUrlOnRouteChange();
  changeUrlOnDateChange();
});

const changeUrlOnRouteChange = () => {
  $(".js-drivers-portal-routes").on("change", function () {
    const date = $(".js-drivers-portal-calendar").val();
    const route = $(this).find(":selected").val();

    changeUrl(date, route);
  });
}

const changeUrlOnDateChange = () => {
  $(".js-drivers-portal-calendar").on("change", function () {
    const date = $(this).val();
    const route = $(".js-drivers-portal-routes").find(":selected").val();

    changeUrl(date, route);
  });
}

const changeUrl = (date, route) => {
  const url = window.location.href.split("?")[0];

  window.location.href = `${url}?date=${date}&route=${route}`;
}
