document.addEventListener("turbolinks:load", function() {
  $(document).on("content_changed", function() {
    [
      ["#package-form", ".name-by-index", "Item", "h1"],
      ["#standard-return-form", ".name-by-index", "Item", "h1"],
      ["#crematory-form", ".name-by-index", "Chamber", "span.title-with-index"],
      ["#crematory-form", ".name-of-child-by-index", "Zone", "span.title-with-index", ".chamber_zones"]
    ].forEach(function(data) {
      dynamicChangeLabelName($(data[0]), data[1], data[2], data[3], data[4]);
      hideFirstHiddenField($(data[0]));
    });
  });

  function dynamicChangeLabelName(form, parent_class, title, selector, closest_class) {
    if (form.length) {
      if (closest_class !== undefined) {
        form.on("cocoon:after-insert", function(e, insertedItem) {
          length = $(insertedItem.closest(`${closest_class}`).find(`${parent_class}:visible`)).length;

          let length = length === 0 ? length + 1 : length;

          insertedItem.find(`${parent_class} ${selector}`).first().text(`${title} ${length}`);
        });

        form.on("cocoon:after-remove", function(e) {
          if ($(e.target).attr("class") === closest_class.split(".").join("")) {
            $(e.target).find(`${parent_class}:visible`).each(function(index) {
              $(this).find(`${selector}`).text(`${title} ${index + 1}`);
            })
          }
        });
      } else {
        form.on("cocoon:after-insert", function(e, insertedItem) {
          insertedItem.find(`${parent_class} ${selector}`).first().text(`${title} ${$(`${parent_class}:visible`).length}`);
        });

        form.on("cocoon:after-remove", function() {
          $(`${parent_class}:visible`).each(function(index) {
            $(this).find(`${selector}`).text(`${title} ${index + 1}`)
          })
        });
      }
    }
  }

  function hideFirstHiddenField(form) {
    if (form.length && $(".remove_fields").length) {
      $(".remove_fields")[0].style.display = "none";
    }
  }
})
