window.changeProductVariantOnSizeChange = (route, id, productId) => {
  $('.js-order-item-variant-size').on('change', function() {
    if ($('.js-order-item-variant-size').length == 1){
      const productVariantSize = $(this).val();

      $('.js-product-variant-material-block').children().remove();
      $('.js-product-variant-color-block').children().remove();
      $('.js-product-variant-style-block').children().remove();
      $('.js-product-variant-shape-block').children().remove();
      $('.js-product-variant-title-block').children().remove();

      $.get({
        dataType: 'script',
        url: `/change-${route}-product-variant-option`,
        data: {
          product_variant_size: productVariantSize,
          id: id,
          product_id: productId
        }
      });
    }
  });
}

window.changeProductVariantOnMaterialChange = (route, id, productId) => {
  $('.js-order-item-variant-material').on('change', function(event) {
    const productVariantSize = $('.js-order-item-variant-size').val();
    const productVariantMaterial = $(this).val();
    const classList = event.currentTarget.classList;
    const expectEmntClass = 'js-order-item-variant-material-';

    if ($('.js-order-item-variant-material').length == 1){
      $('.js-product-variant-color-block').children().remove();
      $('.js-product-variant-style-block').children().remove();
      $('.js-product-variant-shape-block').children().remove();
      $('.js-product-variant-title-block').children().remove();

      $.get({
        dataType: 'script',
        url: `/change-${route}-product-variant-option`,
        data: {
          product_variant_size: productVariantSize,
          product_variant_material: productVariantMaterial,
          id: id,
          product_id: productId
        }
      });
    }

    $.get({
      dataType: 'script',
      url: `/products/${productId}/product_variants/product_variant_image`,
      data: {
        q: {
          size_eq: productVariantSize,
          material_eq: productVariantMaterial
        },
        product_id: productId
      },
      dataType: 'json',
      success: function(response) {
        if (response.variant_image_url){
          for (const clss of classList) {
            if (clss.includes(expectEmntClass)) {
              const index = clss.replace(expectEmntClass, "");
              const imageDivTag = $(`#js-variant-image-${index}`);
              const imageTag = imageDivTag.find('img');
              if (imageTag.length) {
                imageTag.attr('src', response.variant_image_url);
              } else {
                imageDivTag.find('svg').remove();

                var imgTag = $('<img>').attr('src', response.variant_image_url);
                imageDivTag.append(imgTag);
              }
            }
          }
        }
      },
    });
  });
}

window.changeProductVariantOnColorChange = (route, id, productId) => {
  $('.js-order-item-variant-color').on('change', function(event) {
    const productVariantSize = $('.js-order-item-variant-size').val();
    const productVariantMaterial = $('.js-order-item-variant-material').val();
    const productVariantColor = $(this).val();
    const classList = event.currentTarget.classList;
    const expectEmntClass = 'js-order-item-variant-color-';
    if ($('.js-order-item-variant-color').length == 1){
      const productVariantSize = $('.js-order-item-variant-size').val();
      const productVariantMaterial = $('.js-order-item-variant-material').val();
      const productVariantColor = $(this).val();

      $('.js-product-variant-style-block').children().remove();
      $('.js-product-variant-shape-block').children().remove();
      $('.js-product-variant-title-block').children().remove();

      $.get({
        dataType: 'script',
        url: `/change-${route}-product-variant-option`,
        data: {
          product_variant_size: productVariantSize,
          product_variant_material: productVariantMaterial,
          product_variant_color: productVariantColor,
          id: id,
          product_id: productId
        }
      });
    }

    $.get({
      dataType: 'script',
      url: `/products/${productId}/product_variants/product_variant_image`,
      data: {
        q: {
          size_eq: productVariantSize,
          material_eq: productVariantMaterial,
          color_eq: productVariantColor,
        },
        product_id: productId
      },
      dataType: 'json',
      success: function(response) {
        if (response.variant_image_url){
          for (const clss of classList) {
            if (clss.includes(expectEmntClass)) {
              const index = clss.replace(expectEmntClass, "");
              const imageDivTag = $(`#js-variant-image-${index}`);
              const imageTag = imageDivTag.find('img');
              if (imageTag.length) {
                imageTag.attr('src', response.variant_image_url);
              } else {
                imageDivTag.find('svg').remove();

                var imgTag = $('<img>').attr('src', response.variant_image_url);
                imageDivTag.append(imgTag);
              }
            }
          }
        }
      },
    });
  });
}

window.changeProductVariantOnStyleChange = (route, id, productId) => {
  $('.js-order-item-variant-style').on('change', function(event) {
    const productVariantSize = $('.js-order-item-variant-size').val();
    const productVariantMaterial = $('.js-order-item-variant-material').val();
    const productVariantColor = $('.js-order-item-variant-color').val();
    const productVariantStyle = $(this).val();
    const classList = event.currentTarget.classList;
    const expectEmntClass = 'js-order-item-variant-style-';

    if ($('.js-order-item-variant-style').length == 1){
      $('.js-product-variant-shape-block').children().remove();
      $('.js-product-variant-title-block').children().remove();

      $.get({
        dataType: 'script',
        url: `/change-${route}-product-variant-option`,
        data: {
          product_variant_size: productVariantSize,
          product_variant_material: productVariantMaterial,
          product_variant_color: productVariantColor,
          product_variant_style: productVariantStyle,
          id: id,
          product_id: productId
        }
      });
    }

    $.get({
      dataType: 'script',
      url: `/products/${productId}/product_variants/product_variant_image`,
      data: {
        q: {
          size_eq: productVariantSize,
          material_eq: productVariantMaterial,
          color_eq: productVariantColor,
          style_eq: productVariantStyle
        },
        product_id: productId
      },
      dataType: 'json',
      success: function(response) {
        if (response.variant_image_url){
          for (const clss of classList) {
            if (clss.includes(expectEmntClass)) {
              const index = clss.replace(expectEmntClass, "");
              const imageDivTag = $(`#js-variant-image-${index}`);
              const imageTag = imageDivTag.find('img');
              if (imageTag.length) {
                imageTag.attr('src', response.variant_image_url);
              } else {
                imageDivTag.find('svg').remove();

                var imgTag = $('<img>').attr('src', response.variant_image_url);
                imageDivTag.append(imgTag);
              }
            }
          }
        }
      },
    });
  });
}

window.changeProductVariantOnShapeChange = (route, id, productId) => {
  $('.js-order-item-variant-shape').on('change', function(event) {
    const productVariantSize = $('.js-order-item-variant-size').val();
    const productVariantMaterial = $('.js-order-item-variant-material').val();
    const productVariantColor = $('.js-order-item-variant-color').val();
    const productVariantStyle = $('.js-order-item-variant-style').val();
    const productVariantShape = $(this).val();
    const classList = event.currentTarget.classList;
    const expectEmntClass = 'js-order-item-variant-shape-';

    if ($('.js-order-item-variant-shape').length == 1){
      $('.js-product-variant-title-block').children().remove();

      $.get({
        dataType: 'script',
        url: `/change-${route}-product-variant-option`,
        data: {
          product_variant_size: productVariantSize,
          product_variant_material: productVariantMaterial,
          product_variant_color: productVariantColor,
          product_variant_style: productVariantStyle,
          product_variant_shape: productVariantShape,
          id: id,
          product_id: productId
        }
      });
    }

    $.get({
      dataType: 'script',
      url: `/products/${productId}/product_variants/product_variant_image`,
      data: {
        q: {
          size_eq: productVariantSize,
          material_eq: productVariantMaterial,
          color_eq: productVariantColor,
          style_eq: productVariantStyle,
          shape_eq: productVariantShape
        },
        product_id: productId
      },
      dataType: 'json',
      success: function(response) {
        if (response.variant_image_url){
          for (const clss of classList) {
            if (clss.includes(expectEmntClass)) {
              const index = clss.replace(expectEmntClass, "");
              const imageDivTag = $(`#js-variant-image-${index}`);
              const imageTag = imageDivTag.find('img');
              if (imageTag.length) {
                imageTag.attr('src', response.variant_image_url);
              } else {
                imageDivTag.find('svg').remove();

                var imgTag = $('<img>').attr('src', response.variant_image_url);
                imageDivTag.append(imgTag);
              }
            }
          }
        }
      },
    });
  });
}

window.validateEngravingText = line => {
  $('.js-order-item-variant-size').on('change', () => {
    $('.text-count').html(`${$('.engraving-input').val().length} / ${line}`);
  });
  $('.engraving-input').keyup(() => {
    $('.text-count').html(`${$('.engraving-input').val().length} / ${line}`);
  });
}

window.engravingCounter = line => {
  $('.engraving-input2').keyup(() => {
    $('.text-count2').html(`${$('.engraving-input2').val().length} / ${line}`);
  });
}

$(document).on('click', '.add_variant',function (e) {
  const lastCloneVaraint = $('.js-order-item').last();
  const newIndex = parseInt(lastCloneVaraint.attr('id').replace('clone_variant_', "")) + 1 ;
  let variantClone = $('#clone_variant_0').first().clone(true)      
  
  variantClone.attr('id', 'clone_variant_' + parseInt(newIndex));
  variantClone.find('#js-variant-image-0').attr('id', 'js-variant-image-' + parseInt(newIndex));
  // change remove variant icon class
  variantClone.find('.js_remove_variant_0').addClass('js_remove_variant_' + parseInt(newIndex));
  variantClone.find('.js_remove_variant_0').removeClass('js_remove_variant_0');
  // change color select index in class
  variantClone.find('.js-order-item-variant-color-0').addClass('js-order-item-variant-color-' + parseInt(newIndex));
  variantClone.find('.js-order-item-variant-color-0').removeClass('js-order-item-variant-color-0');
  // change material select index in class
  variantClone.find('.js-order-item-variant-material-0').addClass('js-order-item-variant-material-' + parseInt(newIndex));
  variantClone.find('.js-order-item-variant-material-0').removeClass('js-order-item-variant-material-0');
  // change style select index in class
  variantClone.find('.js-order-item-variant-style-0').addClass('js-order-item-variant-style-' + parseInt(newIndex));
  variantClone.find('.js-order-item-variant-style-0').removeClass('js-order-item-variant-style-0');
  // change shape select index in class
  variantClone.find('.js-order-item-variant-shape-0').addClass('js-order-item-variant-shape-' + parseInt(newIndex));
  variantClone.find('.js-order-item-variant-shape-0').removeClass('js-order-item-variant-shape-0');
  // change style select index in class
  variantClone.find('select').attr('id', 'product_variant_style_' + newIndex)
  
  $('#sort_variant').append('<hr />');
  $('#sort_variant').append(variantClone);
  $('.clear_select_js:last').val('');
  $('.product_variant_quantity:last').val('1');
  $('.product-variant-allow-engraving:last').val(null);
  $('.engraving-input-block:last').addClass('d-none')
  $('.hide_remove_variant').css({"display": "block"});
  $('.hide_remove_variant:first').css({"display": "none"});
  $('.hide_hr:last').css({"display": "none"})
  let variant_data_class = ".js_remove_variant_" + parseInt(newIndex);
  $(variant_data_class).attr('data-productable-id', null);  
});

$(document).on('click', '.js_remove_variant', function (event) {
  const classList = event.currentTarget.classList;
  const expectEmntClass = 'js_remove_variant_';
  
  const productableId = $(this).data('productable-id');
  if (productableId){
    const confirmationMessage = "Are you sure you want to delete this item from the order?";
    if (confirm(confirmationMessage)) {
      $.ajax({
        url: `/productables/${productableId}`,
        method: 'DELETE',
        success: function(response) {

          document.getElementById('modal-close').click();
          window.location.reload();
          
          // Handle any further actions after successful deletion
        },
        error: function(xhr, status, error) {
          alert("Error deleting Productable!");
          // Handle error
        }
      });
    }
  }
  else{
    for (const clss of classList) {
      if (clss.includes(expectEmntClass)) {
        const index = clss.replace(expectEmntClass, "");
        $(`#clone_variant_${index}`).remove();
      }
    }
    if ($(".hide_remove_variant").length == 1){
        $('.hide_remove_variant').css({"display": "none"});
    }
  }
});
