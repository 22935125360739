$(document).on("turbolinks:load", function() {
  addCurrentLocation();
  openGoogleDirections();
})

function openGoogleDirections() {
  let hospitalDirections = $("#hospital-directions-list");

  hospitalDirections.on("change", function() {
    window.open(`https://www.google.com/maps/dir/?api=1&origin=${this.value}&destination=${hospitalDirections.data('hospital-address')}`, "_blank");
  })
}

function addCurrentLocation() {
  let newOption = new Option("Current location", "My+Location", true, true);

  $("#hospital-directions-list").prepend(newOption);
}

$(document).on("change", "#js-users-hospital", function() {
  // $(this).closest("form").submit();
  window.location = window.location.pathname + `?hospital_id=${$(this).val()}&changed_hospital_id=${$(this).val()}`;

})

$(document).on("click", "#premier_aftercare_checkbox", function() {
  $(".rebate").toggle($(this).is(':checked'));
  $('.rebate_error').text("");
  $(field.errorClass).text("");
});

$(document).on('click', '#hospital_form_submit_button', function(e) {
  var isValid = true;

  if ($('#premier_aftercare_checkbox').is(':checked')) {
    if ($('.bill_to_owner:checked').length === 0) {
      e.preventDefault();
      $('.rebate_error').text("Enable cremations services for bill to owner to enable this functionality.");
      isValid = false;
    }else{
      $('.rebate_error').text("");
    }

    var fields = [
      { id: '#cremation_rebate', errorClass: '.cremation_rebate_error', errorMessage: "Please enter a valid cremation rebate value." },
      { id: '#memorial_rebate', errorClass: '.memorial_rebate_error', errorMessage: "Please enter a valid memorial rebate value." }
    ];
    fields.forEach(function(field) {
      var value = parseFloat($(field.id).val());
      if (isNaN(value) || value < 0 || value > 100) {
        e.preventDefault();
        $(field.errorClass).text(field.errorMessage);
        isValid = false;
      } else {
        $(field.errorClass).text("");
      }
    });

  }else{
    $("#hospital_form_submit").trigger("click");
  }

  if (isValid) {
    $("#hospital_form_submit").trigger("click");
  }
});

$(document).on('change', '#premier_aftercare_checkbox, #bill_directly_to_owner', function(e) {
  var premierChecked = $('#premier_aftercare_checkbox').is(':checked');
  var billChecked = $('#bill_directly_to_owner').is(':checked');

  $('#bill_directly_to_owner').prop('disabled', premierChecked).css('opacity', premierChecked ? 0.5 : 1);
  $('#premier_aftercare_checkbox').prop('disabled', billChecked).css('opacity', billChecked ? 0.5 : 1);
});