// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("bootstrap");
require("flatpickr");
require("@nathanvda/cocoon");
require("jquery-ui-sortable");

const images = require.context("../src/images", true)
const imagePath = (name) => images(name, true)
window.imagePath = imagePath

import '@fortawesome/fontawesome-free/js/all.js'
import '@fortawesome/fontawesome-free/css/all.css'

import 'bootstrap-icons/font/bootstrap-icons.css'

import "jquery-mask-plugin"

import "lightbox2/dist/js/lightbox.js";
import "lightbox2/dist/css/lightbox.css";

import "javascripts/flatpickr.js"
import "javascripts/google_map.js"
import "javascripts/dependent_fields"
import "javascripts/flash_messages"
import "javascripts/cocoon"
import "javascripts/tooltip.js"
import "javascripts/sidebar"
import "javascripts/sortable"
import "javascripts/pages/hospital"
import "javascripts/pages/price_template"
import "javascripts/pages/upgrade_price_template"
import "javascripts/pages/price"
import "javascripts/pages/service_order"
import "javascripts/pages/location"
import "javascripts/pages/quote"
import "javascripts/pages/standard_return"
import "javascripts/pages/supplies_orders"
import "javascripts/pages/bin"
import "javascripts/pages/chamber"
import "javascripts/pages/station"
import "javascripts/pages/customers_portal/orders_summary"
import "javascripts/pages/customers_portal/memorial"
import "javascripts/pages/drivers_portal/dashboard"
import "javascripts/pages/drivers_portal/order"
import "javascripts/pages/charge"
import "javascripts/pages/routes"
import "javascripts/pages/clone_price"
import "javascripts/pages/clone_variant"
import "javascripts/pages/email_highlight"
import "javascripts/pages/user"
import "javascripts/select2.js"
import "javascripts/select_all.js"
import "javascripts/return_default_product_variant.js"
import "javascripts/order_items/order_urn_product_creation"
import "javascripts/order_items/product_variant"
import "javascripts/stripe.js"
import 'javascripts/pages/affirm_payment_func';
import "javascripts/pages/service_change"
import "javascripts/pages/customers_portal/payment_timer"
import "select2/dist/css/select2.css";
import "stylesheets/application"

import LocalTime from "local-time";
LocalTime.start();

import * as Layout from "javascripts/layout.js";
Layout.init();
$('#loader_back_btn').click(function () {
    $('#loader-container').css('display', 'block');
  });
$(window).on('load', function () {
    $('#loader-container').css('display', 'none');
});
