document.addEventListener("turbolinks:load", function() {
  $(document).on("content_changed", function () {
    if ($("#price-template-form").length) {
      const $priceTemplateForm = $("#price-template-form");

      $priceTemplateForm.on("cocoon:before-insert", function(e, insertedItem) {
        const weightMax = $(".price_template_price_template_items_weight_max input").last().val();

        $(insertedItem).find(".price_template_price_template_items_weight_min input").val(parseInt(weightMax) + 1);
      });
    }
  });
});
