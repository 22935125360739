$(document).on('content_changed', function () {
    let selectedHospitalIds = []
    $('#sort_hospitals').sortable();
    let previousSelectedHospitalIds = [];
    let hospitalSelects = document.getElementsByName('position[]');
    $.each(hospitalSelects, function (index, hospitalSelect) {
        if (hospitalSelect.value) {
            selectedHospitalIds.push(hospitalSelect.value);
        }
    });

    $('#ajax-modal').on('shown.bs.modal', function (e) {
        initSelect();
    });

    $('.route_days_select').on('select2:open', function (e) {
        selectId = $(this).parent().parent().find('select').attr('id');
        previousSelectedHospitalIds.push(selectSelectedValue($(this)));
        let currentSelectedHospital = $(this).val();
        $.each(selectedHospitalIds, function (index, selectSelectedValue) {
            if (currentSelectedHospital !== selectSelectedValue) {
                selectOptionStatus(selectId, selectSelectedValue, true);
            }
        });
    });

    $('.route_days_select').on('change', function (e) {
        let hospitalId = $(this).val();
        $(this).data('previous', hospitalId);
        if (hospitalId) {
            selectedHospitalIds.push(hospitalId);
        }
        prevValue = previousSelectedHospitalIds.pop();
        if (prevValue && prevValue !== hospitalId) {
            selectDisableOptions(prevValue)
        }
    });

    $('.add_hospital').on('click', function (e) {
        $('.route_days_select').select2('destroy');
        let noOfSelect = $('.visiting_route_days').length;
        let hostpitalClone = $('#clone_hospital').first().clone(true)
            .removeAttr('id')
            .append($("<div class='col-sm-auto p-2'><a class='remove_hospital'><i class='fa fa-trash' aria-hidden=true></i></a></div>"))

        hostpitalClone.find('select').attr('id', 'route_visiting_route_days_' + noOfSelect)
        $('#sort_hospitals').append(hostpitalClone)
        initSelect();
    });


    $(document).on('click', '.remove_hospital', function (e) {
        let hospitalId = selectSelectedValue($(this));
        $(this).parent().parent().remove();
        if (hospitalId) {
            selectDisableOptions(hospitalId)
        }
    });

    function initSelect() {
        $.each($('#new_route_position'), function (a) {
            $("[data-behavior='select2']").select2({
                dropdownParent: $('#ajax-modal')
            });
        });
    }



    function selectOptionStatus(hospitalSelect, optionValue, disabled = false){
        $(`#${hospitalSelect}`).children(`option[value=${optionValue}]`).prop('disabled', disabled);
    }

    function selectDisableOptions(hospitalId) {
        selectedHospitalIds = selectedHospitalIds.filter(currentValue => currentValue !== hospitalId);
        selectOptionStatus('new_route_position', hospitalId);
        hospitalSelects = $('[id^="route_visiting_route_days_"]')
        $.each(hospitalSelects, function (index, hospitalSelect) {
            selectOptionStatus(hospitalSelect.id, hospitalId);
        });
    }

    function selectSelectedValue(current){
        return current.parent().parent().find('select option:selected').val();
    }

});
