$(document).on("turbolinks:load", function() {
  $("[data-behaviour='sortable']").sortable({
    update: function() {
      $.ajax({
        url: $(this).data("url"),
        type: "PATCH",
        data: $(this).sortable("serialize")
      });
    }
  });
});
