$(document).on("change", "#memorial_image", function() {
  setLabelAsUploadedFileName(this, "memorial_image");
})

$(document).on("change", "#pet_file", function() {
  setLabelAsUploadedFileName(this, "pet_file");
})

function setLabelAsUploadedFileName(input, imageId) {
  let imageName = $(`#${imageId}`).val().split("\\").slice(-1)[0];
  $("#uploaded-memorial-image-value").next("span").html(` ${imageName}`);

  var reader = new FileReader();

  $("#memrorial_preview_img").removeClass("d-none");

  reader.onload = function(e) {
    $("#memrorial_preview_img").attr("src", e.target.result).width(150).height(200);
  };

  reader.readAsDataURL(input.files[0]);
}
