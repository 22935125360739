$(document).on("turbolinks:load", function() {
  removeFleshMessage();
});

function removeFleshMessage() {
  let fadeOutTime;

  if (window.location.pathname === "/family/login") {
    fadeOutTime = 8000;
  } else {
    fadeOutTime = 3000;
  }

  setTimeout(function() {
    $("div.alert").fadeOut(fadeOutTime, function() {
      $(this).remove();
    });
  }, 3000)

  $("div.alert").addClass("fixed-flash-message");
}