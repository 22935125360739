$(document).on("turbolinks:load", function() {
  toggleStripeForm();
});

window.toggleStripeForm = () => {
  const width = $(window).width();

  // it prevents errors related to duplication of elements (id etc)
  if (width >= 800) {
    $(".js-order-profile-mobile").remove();
  } else {
    $(".js-order-profile-desktop").remove();
  }

  $(".js-charge-payment-method").on("change", function() {
    if ($("#charge_payment_method_credit_card").is(':checked') ||
      $("#order_charge_payment_method_credit_card").is(':checked')) {
      $(".charge-payment-block").show();
      $(".js-charge-payment-method").val('card');
      $("#payment-element").show();
      $(".js-complete-purchase").addClass("d-none");
    }
    else if($("#charge_payment_method_stripe_reader").is(":checked") ||
          $("#order_charge_payment_method_stripe_reader").is(":checked")){
      $(".charge-payment-block").show();
      $(".js-charge-payment-method").val('stripe_reader');
      $("#payment-element").hide();
      $(".js-complete-purchase").addClass("d-none");
    }
     else {
      $(".charge-payment-block").hide();
      $(".js-complete-purchase").removeClass("d-none");
    }
  });
}
