document.addEventListener("turbolinks:load", function() {
  $(document).on("content_changed", function () {
    if ($("#upgrade-price-template-form").length) {
      const $upgradePriceTemplateForm = $("#upgrade-price-template-form");

      $upgradePriceTemplateForm.on("cocoon:before-insert", function(e, insertedItem) {
        const weightMax = $(".upgrade_price_template_upgrade_price_template_items_weight_max input").last().val();

        $(insertedItem).find(".upgrade_price_template_upgrade_price_template_items_weight_min input").val(parseInt(weightMax) + 1);
      });
    }
  });
});


document.addEventListener("turbolinks:load", function() {
  $(document).on("content_changed", function () {
    const $upgradePriceTemplateForm = $("#upgrade-price-template-form");

    // Function to update upgrade_cost field to '0.00' if empty
    function updateUpgradeCostField() {
      const upgradeCostInput = $("#upgrade_price_template_upgrade_price_template_items_attributes_0_upgrade_cost");
      if (upgradeCostInput.length && (upgradeCostInput.val() === null || upgradeCostInput.val().trim() === '')) {
        upgradeCostInput.val('0.00');
      }
    }

    // Call the function when the form is loaded
    updateUpgradeCostField();

    // Call the function when the form content is changed
    $upgradePriceTemplateForm.on("cocoon:before-insert", function() {
      updateUpgradeCostField();
    });

    // Listen for changes in the upgrade_cost input field
    $upgradePriceTemplateForm.on("change", "#upgrade_price_template_upgrade_price_template_items_attributes_0_upgrade_cost", function() {
      const value = $(this).val();
      if (!value || value.trim() === '') {
        $(this).val('0.00');
      }
    });
  });
});
