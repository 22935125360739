$(document).ready(function () {
  function checkInvoicesRunning() {
    $.ajax({
      url: "/billings/invoices_running",
      type: "GET",
      dataType: "json",
      success: function (data) {
        if (data.running) {
          $(".billing-status-spinner").html(
            '<i style="margin-left:20px;" class="fa fa-spinner fa-spin"></i>'
          );
        } else {
          $(".billing-status-spinner").empty();
        }
      },
      error: function (error) {
        console.error("Error:", error);
      },
    });
  }

  checkInvoicesRunning();
});
