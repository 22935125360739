$(document).ready(function() {
  var currentUrl = window.location.href;

  if (currentUrl.includes('highlight=email')) {
    $(".custom-highlight").addClass("highlighted");

    if (currentUrl.includes('family_and_pet_info')) {
      $(".custom-email-validation").addClass("highlighted");
    } else {
      var editLink = document.getElementById('custom-edit-link-e-form');
      if (editLink) {
        setTimeout(function() {
          $(".custom-email-validation").addClass("highlighted");
        }, 1000);
      }
    }
  }
});