document.addEventListener("DOMContentLoaded", function() {
    var inputElement = document.getElementById("charge_amount");

    var customAffirmText = document.getElementById("custom-affirm-text-area");

    if (inputElement){
    inputElement.addEventListener("input", function () {

    if (parseFloat(inputElement.value) < 50.0) {
        customAffirmText.style.display = "none";
    } else {
        customAffirmText.style.display = "flex";
    }
    });
    }
});
