$(document).on("keyup", ".min-value-by-data-value", function(object) {
  $.ajax({
    success: function() {
      setMinValueWithError(object);
    }
  });
})

function setMinValueWithError(object) {
  let $this = $(`#${$(object["target"]).attr("id")}`)
  let dataAssignedValue = $this.data("assignedValue");
  let dataValue = $this.data("value");
  let originalValue = $this.val();

  if ((dataAssignedValue < dataValue) || (originalValue < dataValue)) {
    $this.val(dataValue)
    $this.data("assignedValue", dataValue)
    $this.after(
      "<span class='mt-3 invalid-feedback d-block' id='error-message-about-quantity'><i class='fas fa-exclamation-circle'></i> Quantity cannot be less than original order.</span>"
    );

    setTimeout(function() {
      $("#error-message-about-quantity").remove();
    }, 5000);
  }
}
