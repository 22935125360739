$(document).on('content_changed', function() {
  $('#product_variant_locations_product_variant_ids').on('select2:unselect', function(e) {
    var data = e.params.data;
    if (data.id) {
      $('#location-inputs').append(`<input type="hidden" name="product_variant[locations_product_variants_attributes][${data.id}][_destroy]" value="1">`)
      $(`.location_inputs[data-id=${data.id}]`).hide();
    }
  });

  // Function to generate location inputs
  function generateLocationInputs(location, locationName) {
    let inputName = `product_variant[locations_product_variants_attributes][${location}][cost_per_item]`;
    let inputLabel = `Cost per Item (${locationName})`;
    let publicSitePriceName = `product_variant[locations_product_variants_attributes][${location}][public_site_price]`;
    let publicSitePriceLabel = 'Public Site Price';
    let hiddenInputName = `product_variant[locations_product_variants_attributes][${location}][location_id]`;

    let inputHtml = `
      <div class="row location_inputs" data-id="${location}" >
        <div class="form-group string optional product_cost_per_item form-group-valid col-md-6">
          <label class="string optional" for="${inputName}">${inputLabel}</label>
          <input class="form-control string optional" type="text" value="0.00" name="${inputName}" id="${inputName}">
        </div>
        <div class="form-group string optional product_public_site_price form-group-valid col-md-6">
          <label class="string optional" for="${publicSitePriceName}">${publicSitePriceLabel}</label>
          <input class="form-control string optional" type="text" value="0.00" name="${publicSitePriceName}" id="${publicSitePriceName}">
        </div>
        <input type="hidden" name="${hiddenInputName}" value="${location}">
      </div>`;

    let locationInputs = $('#location-inputs');
    locationInputs.append(inputHtml);
  }

  // Function to auto-populate data for selected locations
  function autoPopulateLocations() {
    var selectedLocations = $('#product_variant_locations_product_variant_ids').val();
    if (selectedLocations && selectedLocations.length > 0) {
      selectedLocations.forEach(function(location) {
        let locationName = $(`#product_variant_locations_product_variant_ids option[value="${location}"]`).text();

        if ($(`.location_inputs[data-id=${location}]`).length === 0) {
          generateLocationInputs(location, locationName);
        }
      });
    }
  }

  // Run autoPopulateLocations on load
  autoPopulateLocations();

  $('#product_variant_locations_product_variant_ids').on('select2:select', function(e) {
    let data = e.params.data;
    let location = data.id;
    let locationName = data.text;

    if ($(`.location_inputs[data-id=${data.id}]`).length > 0) {
      $(`input[name="product_variant[locations_product_variants_attributes][${data.id}][_destroy]"]`).remove();
      $(`.location_inputs[data-id=${data.id}]`).show();
    } else {
      generateLocationInputs(location, locationName);
    }
  });
});