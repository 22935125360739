$(document).on("turbolinks:load", function() {
  removeSummaryBlock();
  disableFormSubmitByEnter();
});

function removeSummaryBlock() {
  $(document).on("click", ".remove-orders-supplies-product", function() {
    $("#order_supplies_product_ids").trigger("change");
  })

  $("#order_supplies_product_ids").on("select2:unselect", function(e) {
    $(`button[data-product-id='${e.params.data.id}']`).click();
  });
}

function disableFormSubmitByEnter() {
  $('#order_supply_form').on('keyup keypress', function(e) {
    let keyCode = e.keyCode || e.which;

    if (keyCode === 13) {
      e.preventDefault();
      $("#supplies-options").trigger("change");

      return false;
    }
  });
}

$(document).on("click", "#save-supply-selection", function() {
  const $form = $(this).parents("form");
  let parentNodeId ="supplies-summary";

  $.get('/supplies_orders/new.js', $form.serialize() + "&" + $.param({
    selectors:["#supplies-summary"],
    parent_node_id: parentNodeId
  }), "script");
});
