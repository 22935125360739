import flatpickr from "flatpickr";

function init() {
  flatpickr("[data-behavior='date-flatpickr']", {
    dateFormat: "Y-m-d",
    altInput: true,
    altFormat: "m/d/Y"
  });
  flatpickr("[data-behavior='date-flatpickr-driver-calendar']", {
    dateFormat: "Y-m-d",
    altInput: true,
    altFormat: "m/d/Y",
    disableMobile: true
  });
  flatpickr("[data-behavior='date-flatpickr-allow-input']", {
    dateFormat: "Y-m-d",
    altInput: true,
    altFormat: "m/d/Y",
    allowInput: true
  });
  flatpickr("[data-behavior='date-flatpickr-allow-input-default']", {
    dateFormat: "Y-m-d",
    altInput: true,
    altFormat: "m/d/Y",
    allowInput: true,
    defaultDate: Date.now()
  });
  flatpickr("[data-behavior='timer-flatpickr']", {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    time_24hr: true,
    defaultHour: 0,
    disableMobile: true,
    static: true
  });
}

document.addEventListener("turbolinks:load", function() {

  init();

  $(document).on("content_changed", function () {
    init();
  });
});
