function places_autocomplete() {
  $inputs = $("[data-behavior='places-autocomplete']");

  if ($inputs.length) {
    $inputs.each(function(index) {
      const namespace = $inputs[index].dataset["namespace"];
      let element;

      if (namespace) {
        element = document.getElementById(`${namespace}_street`);
      } else {
        element = $inputs[index];
      }

      autocomplete = new google.maps.places.Autocomplete(element, {
        componentRestrictions: {
          country: "us"
        }
      });

      if (namespace) {
        google.maps.event.addListener(autocomplete, "place_changed", function() {
          const place = autocomplete.getPlace();

          $street = $(`#${namespace}_street`);
          $city = $(`#${namespace}_city`);
          $state = $(`#${namespace}_state`);
          $zip = $(`#${namespace}_zip`);

          $city.val(null);
          $state.val(null).trigger("change");
          $zip.val(null);

          let streetNumber;
          let route;

          if (place.address_components) {
            for (let component of Array.from(place.address_components)) {
              const addressType = component.types[0];

              switch (addressType) {
                case "street_number":
                  streetNumber = component.short_name;
                  break;
                case "route":
                  route = component.long_name;
                  break;
                case "locality":
                  $city.val(component.long_name);
                  break;
                case "administrative_area_level_1":
                  $state.val(component.short_name).trigger("change");
                  break;
                case "postal_code":
                  $zip.val(component.long_name);
                  break;
              }
            }

            if (route.length && streetNumber.length) {
              $street.val(`${streetNumber} ${route}`);
            } else {
              $street.val(route || streetNumber);
            }
          }
        });
      }
    });
  }
}

function hospitalMap() {
  if ($("#hospital-map").length) {
    let mapData = $("#hospital-map");

    let coordinates = {
      lat: mapData.data("latitude"),
      lng: mapData.data("longitude")
    }

    let mapOptions = {
      center: coordinates,
      zoom: 14
    };

    let map = new google.maps.Map(mapData[0], mapOptions);

    new google.maps.Marker({
      position: coordinates,
      map: map
    });
  }
}

function init() {
  if (typeof(google) != "undefined") {
    places_autocomplete();
    hospitalMap();
  }
}


document.addEventListener("turbolinks:load", function() {
  init();

  $(document).on("content_changed", function() {
    init();
  });
});
