export function init() {
  document.addEventListener("turbolinks:load", function() {
    sidebarMenuPosition();
    phoneMaskWithFlatpickr();
    numberFieldWithoutScroll();
    modalMenuPosition();

    $(document).on("content_changed", function () {
      phoneMaskWithFlatpickr();
    });
  });

  $(document).ready(function() {
    bindAjaxModal();
    initAjaxModal();
    initAutosubmit();
    showHideSidebar();
    showHideMenu();
  });
}

function initAjaxModal() {
  const loadingContent = $("#ajax-modal-content").html();

  $("#ajax-modal").modal({
    backdrop: "static",
    show: false
  });

  $("#ajax-modal").on("show.bs.modal", () => $(".popover").popover("hide"));

  $("#ajax-modal").on("shown.bs.modal", function(e) {
     return $(this).data("related-target", $(e.relatedTarget));
  });

  return $("#ajax-modal").on("hidden.bs.modal", function() {
    $("#ajax-modal-content").html(loadingContent);
    $("#ajax-modal .modal-dialog").attr("class", "modal-dialog");

    return $(".popover").popover("hide");
  });
}

function bindAjaxModal() {
  $(document).on("click", "[data-toggle=ajax-modal]", function() {
    const openAjaxModal = () => {
      const $this = $(this);

      $("#ajax-modal .modal-title").text($this.data("title"));

      if ($this.data("class")) {
        $("#ajax-modal .modal-dialog").addClass($this.data("class"));
      }

      $.getScript($this.attr("href"));

      return $("#ajax-modal").modal("show", $this);
    };

    if ($("#ajax-modal").hasClass("show")) {
      $("#ajax-modal").modal("hide");
      $("#ajax-modal").one("hidden.bs.modal", openAjaxModal);
    } else {
      openAjaxModal();
    }

    return false;
  });
}

function initAutosubmit() {
  $("[data-autosubmit]").off("input").on("input", function() {
    const $form = $(this).parents("form");

    $.get($form.attr("action"), $form.serialize(), function(){}, "script");
  });
}

function sidebarMenuPosition() {
  const minHeight = parseInt($("#sidebarMenu").css("min-height"));

  if (window.innerHeight < minHeight) {
    const $sidebarMenu = $("#sidebarMenu");

    if ($(document).height() < minHeight) {
      $("body").css("height", minHeight);
      $("div.full-screen-height").attr("style", `height: ${minHeight}px !important;`);
    }

    window.addEventListener("scroll", function(e) {
      const offset = window.innerHeight + window.scrollY - minHeight;

      $sidebarMenu.css("bottom", offset < 0 && offset || 0);
    });
  }
}

function modalMenuPosition() {
  $("#order-modal-body").css('padding-top', $('#service-order-header').height());
}

function showHideSidebar() {
  $(document).on("click", "#show-sidebar, #hide-sidebar", function(e) {
    e.preventDefault();

    $("#show-sidebar, #hide-sidebar").parent().toggleClass("d-none");
    $("#sidebarMenu").toggleClass("sidebar-menu-visible");
    $(".main-content").toggleClass("main-content-with-margin");
  });
}

function showHideMenu() {
  $(document).on("click", "#toggle-menu", function(e) {
    e.preventDefault();

    $("#order-menu-wrap").toggleClass("modal-menu-wrap");
    $("#order-menu").toggleClass("modal-menu-visible");
    $(".order-body, .order-details").toggleClass("translateY");
  });
}

function phoneMaskWithFlatpickr() {
  $("[data-mask='flatpickr-date']").next("input").mask("99/99/9999", { placeholder: "MM/DD/YYYY" });
}

function numberFieldWithoutScroll() {
  $(document).on("wheel", "input[data-number-field-scroll=false]", function (e) {
    $(this).blur();
  });
}
