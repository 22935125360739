$(document).on("click", ".station-cremation-ready-toggle", function() {
  let parentId = $(this).data("parentId")
  let $parentDiv = $($(`#${parentId}_table`));

  $(this).closest(".station-cremation-ready-toggles").find("button").each(function() {
    $(this).toggleClass("d-none");
  });

  $parentDiv.toggleClass("d-none");
  $parentDiv.closest("div").find(".pagination").addClass("d-none");
});

$(document).on("click", ".station-cremation-ready-toggle-down", function() {
  let parent = $(`#${$(this).data("parentId")}`);
  let date = parent.data("date");
  let currentDate = location.hash.split("#station_cremation_ready_");

  if (location.search.split("weight").length === 1 || currentDate.length > 1 && currentDate[1] !== date ) {
    $($(parent).find("a[data-value='total-orders']"))[0].click();
  }
});

// fix filters work for pagination
$(document).on("keyup", "#js-station-search", function(e) {
  if (e.keyCode === 13) {
    if ($(this).val().length === 1) {
      $(this).closest("form").submit();
    }
  }
});

$(document).on("change", "#js-station-hospital", function() {
  $(this).closest('form').submit();
});
