function init() {
  $('[data-bs-toggle="tooltip"]').tooltip();
}

document.addEventListener("turbolinks:load", function() {
  init();

  $(document).on("content_changed", function () {
    init();
  });
});
