$(document).on('turbolinks:load', function () {
  createOrderUrnProduct();
});

window.createOrderUrnProduct = () => {
  $('.js-order-urn-select').on('change', function () {
    const orderId = $(this).data('orderId');
    const page = $(this).data('page');
    const savedUrnsIds = $(this).data('savedUrnsIds');
    const familyPortalSummary = page.includes('family') && page.includes('?review_step=order_summary');
    const billTo = familyPortalSummary ? 'customer' : 'hospital';
    const cart = familyPortalSummary;

    let urnsIds = $(this).val();
    urnsIds = urnsIds.map(productId => parseInt(productId));

    let productId = urnsIds.filter(productId => !savedUrnsIds.includes(productId));
    productId = productId.toString();

    $.post({
      dataType: 'script',
      url: '/orders_urns_products',
      data: {
        orders_urns_product: {
          order_id: orderId,
          product_id: productId,
          bill_to: billTo,
          cart: cart
        }
      }
    });
  });
}
