window.changeOrderRoute = (orderRecordId, serviceChangeType, routeToProfile, loggedInUserId) => {
    document.getElementById("upgrade-process-btn").disabled = true;
    document.getElementById("upgrade-cancel-process-btn").disabled = true;
    routeToNextPage(orderRecordId, serviceChangeType, routeToProfile, loggedInUserId);
};

window.routeToNextPage = (orderRecordId, serviceChangeType, routeToProfile, loggedInUserId) => {
    event.preventDefault();
    fetch('/service_orders/process_service_change', {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            order_id: orderRecordId,
            route_to_profile: routeToProfile,
            user_id: loggedInUserId,
            service_change_type: serviceChangeType
        }),
    })
    .then(response => response.json())
    .then(data => {
        console.log(data);
        if (data["next_route"] == "edit")
            window.location.href = window.location.href + "/edit?service_changed=yes";
        else if(data["next_route"] == "order-profile")
            window.location.href = window.location.href + "?tab=order-profile";
    })
    .catch(error => {
        console.error('Error:', error);
    });
};

window.cancelUpgrade = () => {
    document.getElementById("upgrade-cancel-process-btn").disabled = true;
    document.getElementById("upgrade-process-btn").disabled = true;
    event.preventDefault();
    location.reload();
};