$(document).on('content_changed', function () {

  $('#product_locations_product_ids').on('select2:unselect', function (e) {
    var data = e.params.data;
    if (data.id) {
      $('#location-inputs').append(`<input type="hidden" name="product[locations_products_attributes][${data.id}][_destroy]" value="1">`)
      if ($('#product_variants').val() == 'yes') {
        let hiddenInputName = `product[locations_products_attributes][${data.id}][location_id]`;
        let inputHtml = `
                <div class="row location_inputs" data-id="${location}" >
                  <input type="hidden" name="${hiddenInputName}" value="${data.id}">
                </div>`;
        let locationInputs = $('#location-inputs');
        locationInputs.append(inputHtml);
      }
      $(`.location_inputs[data-id=${data.id}]`).hide();
    }
  });

  $('#product_variants').on('change', function (e) {
    $('.location_inputs').remove();
    $('#product_locations_product_ids').val(null).trigger('change');
  });


  $('#product_locations_product_ids').on('select2:select', function (e) {
    let data = e.params.data;
    let location = data.id;
    let locationName = data.text;
    if ($('#product_variants').val() == 'yes') {
      let hiddenInputName = `product[locations_products_attributes][${location}][location_id]`;
      let inputHtml = `
              <div class="row location_inputs" data-id="${location}" >
                <input type="hidden" name="${hiddenInputName}" value="${location}">
              </div>`;
      let locationInputs = $('#location-inputs');
      locationInputs.append(inputHtml);
      locationInputs.hide();
      return
     }
    if ($(`.location_inputs[data-id=${data.id}]`).length > 0) {
      $(`input[name="product[locations_products_attributes][${data.id}][_destroy]"]`).remove();

      $(`.location_inputs[data-id=${data.id}]`).show();
    } else {
      let inputName = `product[locations_products_attributes][${location}][cost_per_item]`;
      let inputLabel = `Cost per Item (${locationName})`;
      let publicSitePriceName = `product[locations_products_attributes][${location}][public_site_price]`;
      let publicSitePriceLabel = 'Public Site Price';
      let hiddenInputName = `product[locations_products_attributes][${location}][location_id]`;

      let inputHtml = `
              <div class="row location_inputs" data-id="${location}" >
                <div class="form-group string optional product_cost_per_item form-group-valid col-md-6">
                  <label class="string optional" for="${inputName}">${inputLabel}</label>
                  <input class="form-control string optional" type="text" value="0.00" name="${inputName}" id="${inputName}">
                </div>
                <div class="form-group string optional product_public_site_price form-group-valid col-md-6">
                  <label class="string optional" for="${publicSitePriceName}">${publicSitePriceLabel}</label>
                  <input class="form-control string optional" type="text" value="0.00" name="${publicSitePriceName}" id="${publicSitePriceName}">
                </div><input type="hidden" name="${hiddenInputName}" value="${location}">
              </div>`;
      let locationInputs = $('#location-inputs');
      locationInputs.append(inputHtml);
    }
  });

  });
