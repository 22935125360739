$(document).on("turbolinks:load", function() {
  addAutofocus();
})

function addAutofocus() {
  $("#ajax-modal").on("shown.bs.modal", function() {
    if ($("#quote_body").length) {
      $("#quote_body").focus();
    }
  })
}
